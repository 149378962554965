import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'
import Nav from '../components/nav/nav'
import '../css/contact.scss'
import ArrowRight from '../images/icons/arrow-right.svg'
import ContactLetter from '../images/icons/contact.svg'
import Axios from 'axios'
import swal from 'sweetalert';
import BounceLoader from "react-spinners/BounceLoader";

const CompanyProfile = () => {
    const [userOption, setUserOption] = useState('')
    const [acceptedTerms, setAcceptedTerms] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [message, setMessage] = useState('')

    const [firstNameError, setFirstNameError] = useState('')
    const [lastNameError, setLastNameError] = useState('')
    const [companyNameError, setCompanyNameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [phoneNumberError, setPhoneNumberError] = useState('')
    const [messageError, setMessageError] = useState('')

    const [loader, setLoader] = useState(false)


    const acceptTerms = () => {
        setAcceptedTerms(true)
    }

    const selectedOptionConversion = (option) => {
        switch (option) {
            case 'digital':
                return 'Digital'
                break;
            case 'risk':
                return 'Risk Management'
                break;
            case 'human':
                return 'Human Capital Development'
                break;
            case 'transformation':
                return 'Transformation'
                break;
            case 'intelligent':
                return 'Intelligent Automation'
                break;
            case 'academy':
                return 'Procentric Academy'
                break;
            default:
                break;
        }
    }

    const selectUserOption = (option) => {
        setUserOption(option)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoader(true)

        const data = {
            solution: selectedOptionConversion(userOption),
            first_name: firstName,
            last_name: lastName,
            company_name: companyName,
            email,
            phone_number: phoneNumber,
            message
        }


        try {
            let response = await Axios.post('https://procentriciq.com/portal/api/send-message', data);

            if (response.status === 200) {
                swal("Message sent", "Thanks for contacting us. Your Download will commence soon", "success")
                    .then(() => {
                        window.open('https://procentriciq.com/company-profile/company-profile.pdf', '_blank');
                    });

            }

        } catch (err) {
            console.error(err)
        } finally {
            setLoader(false)
        }

        setUserOption('')
        setFirstName('')
        setLastName('')
        setCompanyName('')
        setEmail('')
        setPhoneNumber('')
        setMessage('')
    }

    const handleChange = (e) => {
        const { value, name } = e.target
        switch (name) {
            case 'firstName':
                setFirstName(value)
                break;
            case 'lastName':
                setLastName(value)
                break;
            case 'companyName':
                setCompanyName(value)
                break;
            case 'email':
                setEmail(value)
                break;
            case 'phoneNumber':
                setPhoneNumber(value)
                break;
            case 'message':
                setMessage(value)
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        setUserOption('')
        setFirstName('')
        setLastName('')
        setCompanyName('')
        setEmail('')
        setPhoneNumber('')
        setMessage('')
    }, [])

    return (
        <Layout>
            <Nav
                isWhite={true}
            />
            <div className="contact pt-5">
                <div className="position-relative">
                    <div className="bg-contact py-5">
                        <div className="container py-3 my-4">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md-1 px-0 mt-4">
                                            <div className="d-md-block d-none" style={{ borderBottom: '3px solid #ffffff' }}></div>
                                        </div>
                                        <div className="col-md-8">
                                            <h1 className="text-white font-weight-bold" style={{ lineHeight: '58px', fontSize: '2.8rem' }}>Company Profile</h1>

                                            <p className="text-white mb-0" style={{ lineHeight: '22px', fontSize: '1.3rem' }}>Fire up your engines. Blast off. We will get you where you want to go. Fast.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 my-auto">

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="contact-letter-div mb-5">
                        <img src={ContactLetter} alt="" className="contact-img" />
                    </div>
                </div>

                <div className="my-md-5 py-md-5"></div>
                <div className="py-5"></div>

                <div className="container">


                    <div className="row">
                        <div className="col-md-8">
                            <h3 className="font-weight-bold mb-3" style={{ fontSize: '2.8rem' }}>Fill this form and the company profile will be downloaded automatically</h3>

                            <p className="text-secondary">Let’s get to know you first...</p>

                            <div className="row mb-5">
                                <div className="col-md-4">
                                    <div className={`d-inline-flex cursor ${userOption === 'digital' ? "active-checkbox" : ""}`} onClick={() => selectUserOption('digital')}>
                                        <div className="check-box__custom mr-1 cursor">
                                            <div className="check-box-custom__active"></div>
                                        </div>
                                        <p className="">Digital</p>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className={`d-inline-flex cursor ${userOption === 'risk' ? "active-checkbox" : ""}`} onClick={() => selectUserOption('risk')}>
                                        <div className="check-box__custom mr-1 cursor">
                                            <div className="check-box-custom__active"></div>
                                        </div>
                                        <p className="">Risk Management</p>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className={`d-inline-flex cursor ${userOption === 'human' ? "active-checkbox" : ""}`} onClick={() => selectUserOption('human')}>
                                        <div className="check-box__custom mr-1 cursor">
                                            <div className="check-box-custom__active"></div>
                                        </div>
                                        <p className="">Human Capital Development</p>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className={`d-inline-flex cursor ${userOption === 'transformation' ? "active-checkbox" : ""}`} onClick={() => selectUserOption('transformation')}>
                                        <div className="check-box__custom mr-1 cursor">
                                            <div className="check-box-custom__active"></div>
                                        </div>
                                        <p className="">Transformation</p>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className={`d-inline-flex cursor ${userOption === 'intelligent' ? "active-checkbox" : ""}`} onClick={() => selectUserOption('intelligent')}>
                                        <div className="check-box__custom mr-1 cursor">
                                            <div className="check-box-custom__active"></div>
                                        </div>
                                        <p className="">Intelligent Automation</p>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className={`d-inline-flex cursor ${userOption === 'academy' ? "active-checkbox" : ""}`} onClick={() => selectUserOption('academy')}>
                                        <div className="check-box__custom mr-1 cursor">
                                            <div className="check-box-custom__active"></div>
                                        </div>
                                        <p className="">Procentric Academy</p>
                                    </div>
                                </div>
                            </div>

                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-10">
                                        <div className="row">
                                            <div className="col-md-4 mb-4">
                                                <input
                                                    required
                                                    className="form-control"
                                                    placeholder="First Name"
                                                    name="firstName"
                                                    id="firstName"
                                                    value={firstName}
                                                    onChange={handleChange}
                                                />
                                                {/* <p className="small text-danger mb-0 pb-0">Enter first name</p> */}
                                            </div>

                                            <div className="col-md-4 mb-4">
                                                <input
                                                    required
                                                    className="form-control"
                                                    placeholder="Last Name"
                                                    name="lastName"
                                                    id="lastName"
                                                    value={lastName}
                                                    onChange={handleChange}
                                                />
                                                {/* <p className="small text-danger mb-0 pb-0">Enter last name</p> */}
                                            </div>

                                            <div className="col-md-4 mb-4">
                                                <input
                                                    required
                                                    className="form-control"
                                                    placeholder="Company Name"
                                                    id="companyName"
                                                    name="companyName"
                                                    value={companyName}
                                                    onChange={handleChange}
                                                />
                                                {/* <p className="small text-danger mb-0 pb-0">Enter company name</p> */}
                                            </div>

                                            <div className="col-md-6 mb-4">
                                                <input
                                                    required
                                                    className="form-control"
                                                    placeholder="Email Address"
                                                    id="email"
                                                    name="email"
                                                    value={email}
                                                    onChange={handleChange}
                                                />
                                                {/* <p className="small text-danger mb-0 pb-0">Enter Email Address</p> */}
                                            </div>

                                            <div className="col-md-6 mb-4">
                                                <input
                                                    required
                                                    className="form-control"
                                                    placeholder="Phone Number"
                                                    id="phoneNumber"
                                                    name="phoneNumber"
                                                    value={phoneNumber}
                                                    onChange={handleChange}
                                                />
                                                {/* <p className="small text-danger mb-0 pb-0">Enter phone number</p> */}
                                            </div>

                                            <div className="col-md-12 mb-4">
                                                <textarea
                                                    required
                                                    cols="50"
                                                    className="form-control w-100"
                                                    placeholder="message"
                                                    id="message"
                                                    name="message"
                                                    value={message}
                                                    onChange={handleChange}
                                                />
                                                {/* <p className="small text-danger mb-0 pb-0">Enter message</p> */}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <i className="small">The profile will be downloaded automatically</i>
                                            </div>
                                        </div>

                                        <div className="row mb-5">
                                            <div className="col-md-8">
                                            </div>

                                            <div className="col-md-4 text-md-right mt-md-0 mt-4">
                                                <button disabled={loader} type="submit" className="btn btn-red btn-small h-100 btn-red-black px-4 no-border-radius">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        DOWNLOAD
                                                        
                                                        {
                                                            loader && <BounceLoader 
                                                            size={15}
                                                            color="#fff"
                                                            />
                                                        }
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>

                    <div className="row my-5 py-md-5">
                        <div className="col-md-3">
                            <h3 className="font-weight-bold mb-4" style={{ fontSize: '2.8rem' }}>Abuja</h3>
                            <p className="text-secondary mb-1" style={{ fontSize: '18px' }}> 4th Floor, Building 2, Rivers House, Central Business District</p>
                            <p className="text-secondary mb-2" style={{ fontSize: '18px' }}>Abuja, Nigeria.</p>
                            <p className="text-secondary" style={{ fontSize: '17px' }}>+234 (0) 14605067</p>

                            <a href="mailto:info@procentriciq.com"><p className="red-link-text">info@procentriciq.com</p></a>
                        </div>

                        <div className="col-md-4">
                            <h3 className="font-weight-bold mb-4" style={{ fontSize: '2.8rem' }}>Lagos</h3>

                            <p className="text-secondary mb-1" style={{ fontSize: '18px' }}>6th Floor, Landmark Towers, 5B Water Corporation Street, Oniru, Victoria Island,</p>
                            <p className="text-secondary mb-2" style={{ fontSize: '18px' }}>Lagos, Nigeria.</p>
                            <p className="text-secondary" style={{ fontSize: '17px' }}>+234 (0) 14605067</p>

                            <a href="mailto:info@procentriciq.com"><p className="red-link-text">info@procentriciq.com</p></a>
                        </div>
                    </div>

                    <div className="py-5"></div>


                </div>
            </div>
        </Layout>
    )
}

export default CompanyProfile
